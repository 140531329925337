import Head from "next/head"
import { Text, Button, Box, Image, VStack, Link } from "@chakra-ui/react"
import { Heading } from "@chakra-ui/react"
import { useMediaQuery } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useTranslations } from "next-intl"
import useMemberStore from "@/store/MemberStore"
import HrefLang from "@/components/HrefLang"
import NextLink from "next/link"

export default function Home() {
  const [isDesktop] = useMediaQuery("(min-width: 800px)")
  const maxWidth = isDesktop ? "1100px" : "100%"
  const minWidth = isDesktop ? "600px" : "100%"
  const t = useTranslations("HomePage")
  const title = t("Meta.title")
  const description = t("Meta.description")
  const router = useRouter()
  const { locale } = router
  // const locale = "zh-TW"
  const { member } = useMemberStore()

  useEffect(() => {
    const accounts = member ? member.connectedThreadsAccounts : []
    const account = accounts.length > 0 ? accounts[0] : null
    if (!member) {
      return
    }
    if (account) {
      const url = `/dashboard/${account.username}`
      router.push(url)
    } else {
      const url = `/threads/connect`
      router.push(url)
    }
  }, [member, router])

  return (
    <Box
      bg="ui.foreground1"
      maxWidth={maxWidth}
      minWidth={minWidth}
      paddingLeft="16px"
      paddingRight="16px">
      <Head>
        <title>{title}</title>
        <meta name="og:title" content={title} />
        <meta name="og:description" content={description} />
        <meta name="description" content={description} />
      </Head>
      <HrefLang />

      <Box marginTop="40px">
        <VStack>
          <Heading as="h1" size="2xl" textAlign="center">
            {t("Title.masterYourThreadsData")}
          </Heading>
          <Link as={NextLink} href="/signup">
            <Button
              size="lg"
              background="main.red"
              marginTop="30px"
              marginBottom="30px">
              {t("Action.freeTrialButton")}
            </Button>
          </Link>
          <Heading as="h2" size="lg" textAlign="center" whiteSpace="pre-line">
            {t("Title.followersGraph")}
          </Heading>
          <Text fontSize="20px" textAlign="center" whiteSpace="pre-line">
            {t("Description.followersGraph")}
          </Text>
          <Image
            src={`./landingpage-${locale}-1.jpg`}
            alt="Landing Page Image 1"
            marginTop="16px"
            marginBottom="16px"
            borderRadius="16px"
          />

          <Heading as="h2" size="lg" textAlign="center" whiteSpace="pre-line">
            {t("Title.schedulePost")}
          </Heading>
          <Text fontSize="20px" textAlign="center" whiteSpace="pre-line">
            {t("Description.schedulePost")}
          </Text>
          <Image
            src={`./landingpage-${locale}-5.jpg`}
            alt="Landing Page Image 5"
            marginTop="16px"
            marginBottom="16px"
            borderRadius="16px"
          />

          {/* <Heading as="h2" size="lg" textAlign="center" whiteSpace="pre-line">
            {t("Title.followersDemographics")}
          </Heading>
          <Text fontSize="20px" textAlign="center" whiteSpace="pre-line">
            {t("Description.followersDemographics")}
          </Text>
          <Image
            src={`./landingpage-${locale}-4.jpg`}
            alt="Landing Page Image 1"
            marginTop="16px"
            borderRadius="16px"
          /> */}

          {/* <Heading as="h2" marginTop="16px" size="lg">
            {t("Title.threadPostAnalytics")}
          </Heading>
          <Text fontSize="20px" textAlign="center" whiteSpace="pre-line">
            {t("Description.threadPostAnalytics")}
          </Text>
          <Image
            src={`./landingpage-${locale}-2.jpg`}
            alt="Landing Page Image 1"
            marginTop="16px"
            borderRadius="16px"
          /> */}
        </VStack>
      </Box>
    </Box>
  )
}

export async function getServerSideProps(context) {
  return {
    props: {
      messages: (await import(`@/messages/${context.locale}.json`)).default,
      // messages: (await import(`@/messages/zh-TW.json`)).default,
    },
  }
}
